import React, { useState, useEffect } from 'react';
import './EditCameraDirection.css';
import { Button, Modal, Form } from 'react-bootstrap';

const EditCameraDirection = ({ show, instance, index, onSubmit, onHide }) => {
    const [formData, setFormData] = useState({});

    // Initialize form data when instance changes
    useEffect(() => {
        if (instance) {
            setFormData(instance);
        }
    }, [instance]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        onSubmit(index, formData);
        onHide();
    };

    const shotTypes = [
        'Wide shot',
        'Full shot',
        'Medium',
        'Medium close-up',
        'Establishing shot',
        'High angle',
        'Low angle',
        'Two shot',
        'Three shot',
        'Close-up',
    ];

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Edit Camera Direction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Shot Type</Form.Label>
                        <Form.Control
                            className="dropdown_menu"
                            as="select"
                            name="shot_type"
                            value={formData.shot_type || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Shot Type</option>
                            {shotTypes.map((shot, i) => (
                                <option key={i} value={shot}>
                                    {shot}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Movement Type</Form.Label>
                        <Form.Control type="text" name="movement_type" value={formData.movement_type || ''} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" name="subject" value={formData.subject || ''} onChange={handleInputChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCameraDirection;
