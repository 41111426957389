import { getEnvVariable } from './env';
import { Script, Scene, Story } from '../types/ProtoDSS';

const MAIN_API_URL = getEnvVariable('REACT_APP_MAIN_API_URL');

interface DataType {
    story: Story;
    script: Script;
}

interface GenerateSceneResponse {
    scene: string;
}

export const generate_scene = async (isBasicInput: boolean, isFirst: boolean, data: DataType): Promise<string> => {
    let url: string;
    let request_body: string;

    if (isFirst) {
        url = `${MAIN_API_URL}/generate_first_scene`;
        request_body = isBasicInput ? JSON.stringify({ premise: data.story.basic_prompt }) : JSON.stringify(data.story.advanced_prompt);
    } else {
        url = `${MAIN_API_URL}/generate_additional_scene`;
        request_body = JSON.stringify({
            premise: {
                premise: isBasicInput ? data.story.basic_prompt : data.story.advanced_prompt,
            },
            existing_script: data.script.scenes,
        });
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: request_body,
    });

    const result: GenerateSceneResponse = await response.json();

    return result.scene;
};

export const generate_metadata = async (data: DataType): Promise<any> => {
    const url = `${MAIN_API_URL}/generate_story_metadata`;

    const request_body = JSON.stringify({
        request: data.script.scenes.map((scene: Scene) => scene.scene_text).join(' NEW SCENE '),
    });

    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: request_body,
    });

    return await response.json();
};
