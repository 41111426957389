export function constructPublicUrl(s3Url: string, region = '') {
    if (!s3Url.startsWith('s3://')) {
        return s3Url;
    }
    const urlParts = s3Url.replace(/^s3:\/\//, '').split('/');
    const bucket = urlParts.shift();
    const key = urlParts.join('/');

    if (region !== undefined && region !== '') {
        region = '.' + region;
    } else {
        region = '';
    }
    return `https://${bucket}.s3${region}.amazonaws.com/${key}`;
}
