// VoicesPage.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import './VoicesPage.css';
// import PropTypes from 'prop-types';

// import 'bootstrap/dist/css/bootstrap.min.css';

export const VOICES = [
    {
        voice_id: 'EXAVITQu4vr4xnSDxMaL',
        name: 'Sarah',
        labels: {
            gender: 'female',
            accent: 'American',
            use_case: 'news',
            age: 'young',
            description: 'soft',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/01a3e33c-6e99-4ee7-8543-ff2216a32186.mp3',
    },
    {
        voice_id: 'FGY2WhTYpPnrIDTdsKH5',
        name: 'Laura',
        labels: {
            gender: 'female',
            accent: 'American',
            use_case: 'social media',
            age: 'young',
            description: 'upbeat',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/FGY2WhTYpPnrIDTdsKH5/67341759-ad08-41a5-be6e-de12fe448618.mp3',
    },
    {
        voice_id: 'IKne3meq5aSn9XLyUdCD',
        name: 'Charlie',
        labels: {
            gender: 'male',
            accent: 'Australian',
            use_case: 'conversational',
            age: 'middle-aged',
            description: 'natural',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3',
    },
    {
        voice_id: 'JBFqnCBsd6RMkjVDRZzb',
        name: 'George',
        labels: {
            gender: 'male',
            accent: 'British',
            use_case: 'narration',
            age: 'middle-aged',
            description: 'warm',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/e6206d1a-0721-4787-aafb-06a6e705cac5.mp3',
    },
    {
        voice_id: 'N2lVS1w4EtoT3dr4eOWO',
        name: 'Callum',
        labels: {
            gender: 'male',
            accent: 'Transatlantic',
            use_case: 'characters',
            age: 'middle-aged',
            description: 'intense',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3',
    },
    {
        voice_id: 'TX3LPaxmHKxFdv7VOQHJ',
        name: 'Liam',
        labels: {
            gender: 'male',
            accent: 'American',
            use_case: 'narration',
            age: 'young',
            description: 'articulate',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3',
    },
    {
        voice_id: 'XB0fDUnXU5powFXDhCwa',
        name: 'Charlotte',
        labels: {
            gender: 'female',
            accent: 'Swedish',
            use_case: 'characters',
            age: 'young',
            description: 'seductive',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3',
    },
    {
        voice_id: 'Xb7hH8MSUJpSbSDYk0k2',
        name: 'Alice',
        labels: {
            gender: 'female',
            accent: 'British',
            use_case: 'news',
            age: 'middle-aged',
            description: 'confident',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/d10f7534-11f6-41fe-a012-2de1e482d336.mp3',
    },
    {
        voice_id: 'XrExE9yKIg1WjnnlVkGX',
        name: 'Matilda',
        labels: {
            gender: 'female',
            accent: 'American',
            use_case: 'narration',
            age: 'middle-aged',
            description: 'friendly',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3',
    },
    {
        voice_id: 'bIHbv24MWmeRgasZH58o',
        name: 'Will',
        labels: {
            gender: 'male',
            accent: 'American',
            use_case: 'social media',
            age: 'young',
            description: 'friendly',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/bIHbv24MWmeRgasZH58o/8caf8f3d-ad29-4980-af41-53f20c72d7a4.mp3',
    },
    {
        voice_id: 'cgSgspJ2msm6clMCkdW9',
        name: 'Jessica',
        labels: {
            gender: 'female',
            accent: 'American',
            use_case: 'conversational',
            age: 'young',
            description: 'expressive',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/cgSgspJ2msm6clMCkdW9/56a97bf8-b69b-448f-846c-c3a11683d45a.mp3',
    },
    {
        voice_id: 'cjVigY5qzO86Huf0OWal',
        name: 'Eric',
        labels: {
            gender: 'male',
            accent: 'American',
            use_case: 'conversational',
            age: 'middle-aged',
            description: 'friendly',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/cjVigY5qzO86Huf0OWal/d098fda0-6456-4030-b3d8-63aa048c9070.mp3',
    },
    {
        voice_id: 'iP95p4xoKVk53GoZ742B',
        name: 'Chris',
        labels: {
            gender: 'male',
            accent: 'American',
            use_case: 'conversational',
            age: 'middle-aged',
            description: 'casual',
        },
        preview_url:
            'https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/3f4bde72-cc48-40dd-829f-57fbf906f4d7.mp3',
    },
];

const VoicesPage = ({ onSelectVoice, show, handleClose }) => {
    const [selectedVoice, setSelectedVoice] = useState(VOICES[0]);

    const handleVoiceChange = event => {
        const selectedId = event.target.value;
        const voice = VOICES.find(v => v.voice_id === selectedId);
        setSelectedVoice(voice);
    };

    const handleSelectVoice = () => {
        onSelectVoice(selectedVoice.voice_id);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Select a Voice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Choose a voice:</Form.Label>
                        <Form.Control className="dropdown_menu" as="select" onChange={handleVoiceChange} value={selectedVoice.voice_id}>
                            {VOICES.map(voice => (
                                <option key={voice.voice_id} value={voice.voice_id}>
                                    {voice.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {selectedVoice && (
                        <div className="mt-3">
                            <h5>Voice Traits:</h5>
                            <p>
                                <strong>Description:</strong> {selectedVoice.labels.description}
                            </p>
                            <p>
                                <strong>Accent:</strong> {selectedVoice.labels.accent}
                            </p>
                            <p>
                                <strong>Gender:</strong> {selectedVoice.labels.gender}
                            </p>
                            <p>
                                <strong>Use Case:</strong> {selectedVoice.labels.use_case}
                            </p>
                            <p>
                                <strong>Age:</strong> {selectedVoice.labels.age}
                            </p>
                            <audio controls className="w-100" key={selectedVoice.voice_id}>
                                <source src={selectedVoice.preview_url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSelectVoice}>
                    Select Voice
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VoicesPage;
