import React from 'react';
import './StoryView.css';
import Basic from './Basic';
import Advanced from './Advanced';
import { Tab, Tabs, Form, Button, Container, Row, Col } from 'react-bootstrap';

const StoryView = ({ storyInputMode, setStoryInputMode, data, updateData, generateScript }) => {
    const handleSceneCountChange = event => {
        const scene_count = parseInt(event.target.value, 10);
        updateData({ ...data, scene_count: scene_count });
    };

    return (
        <div className="App-content">
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={10} md={10} className="border p-4 shadow rounded position-relative">
                        <Tabs activeKey={storyInputMode} onSelect={k => setStoryInputMode(k)}>
                            <Tab eventKey="Basic" title="Basic">
                                <Basic data={data.basic_prompt} updateData={value => updateData({ ...data, basic_prompt: value })} />
                            </Tab>
                            <Tab eventKey="Advanced" title="Advanced">
                                <Advanced
                                    data={data.advanced_prompt}
                                    updateData={value => updateData({ ...data, advanced_prompt: value })}
                                />
                            </Tab>
                        </Tabs>
                        <Form>
                            <Form.Group className="m-3">
                                <Form.Label>Scene Count</Form.Label>
                                <Form.Control type="number" min="1" value={data.scene_count} onChange={handleSceneCountChange} />
                            </Form.Group>
                        </Form>
                        <div className="d-grid gap-2 m-3">
                            <Button variant="success" onClick={generateScript}>
                                Generate Script
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StoryView;
