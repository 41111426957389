import React from 'react';
import './TitleBar.css';
import { Button, Badge } from 'react-bootstrap';

interface TitleBarProps {
    data: string;
    updateData: (newData: string) => void;
    createStory: () => void;
    saveStatus: 'Saved' | 'Unsaved';
    createStoryActive: boolean;
}

const TitleBar: React.FC<TitleBarProps> = ({ data, updateData, createStory, saveStatus, createStoryActive }) => {
    const handleChange = (event: React.FocusEvent<HTMLHeadingElement>) => {
        updateData(event.target.innerText);
    };

    // TODO: Add tooltip to button
    return (
        <div className="App-title">
            <h1 contentEditable suppressContentEditableWarning className="project-title" onBlur={handleChange}>
                {data}
            </h1>
            <div className="title-bar-actions">
                <Badge bg={saveStatus === 'Saved' ? 'success' : 'warning'} className="ms-2">
                    {saveStatus}
                </Badge>
                <Button variant="success" onClick={createStory} disabled={!createStoryActive}>
                    Render Video
                </Button>
            </div>
        </div>
    );
};

export default TitleBar;
