import Cookies from 'js-cookie';
import { getEnvVariable } from './env';

const DATABASE_API_URL = getEnvVariable('REACT_APP_DATABASE_API_URL');
export const AuthErrPrefix = 'Auth error:';

export const authError = (msg: string) => {
    throw new Error(`${AuthErrPrefix} ${msg}`);
};

export const fetchWithToken = async (
    url: string | URL | Request,
    options = {
        headers: undefined,
    },
    retry = true,
) => {
    const sessionToken = Cookies.get('session_token');
    if (!sessionToken) {
        throw authError('No session token found');
    }
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                Authorization: `Bearer ${sessionToken}`,
                ...options.headers,
            },
        });
        if (response.status === 401) {
            throw authError('Unauthorized');
        }
        if (!response.ok) {
            throw new Error('Failed to fetch');
        }
        return response;
    } catch (error) {
        try {
            await refreshToken();
        } catch (refreshError) {
            // eslint-disable-next-line no-console
            console.error('Error refreshing token:', refreshError);
            throw authError(refreshError.message);
        }
        if (retry) {
            return await fetchWithToken(url, options, false);
        }
        throw error;
    }
};

export const refreshToken = async () => {
    let sessionToken = Cookies.get('session_token');
    if (!sessionToken) {
        throw new Error('No session token found');
    }
    Cookies.remove('session_token');
    const response = await fetch(`${DATABASE_API_URL}/v1/refresh_token`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${sessionToken}`,
        },
    });
    if (!response.ok) {
        if ((await response.text()) === 'No refresh token available') {
            throw new Error('No refresh token available');
        }
        // eslint-disable-next-line no-console
        console.error('Failed to refresh token', await response.text());
        throw new Error('Failed to refresh token');
    }
    const respObj = await response.json();
    sessionToken = respObj.session_token;
    Cookies.set('session_token', sessionToken);
};
