declare global {
    interface Window {
        _env_?: { [key: string]: string };
    }
}

export const getEnvVariable = (key: string): string | undefined => {
    if (window._env_ && window._env_[key]) {
        return window._env_[key];
    }
    return process.env[key];
};
