import React, { ReactNode, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import Header from './components/Header';
import ProjectsView from './components/Projects/ProjectsView';
import StudioView from './components/Studio/StudioView';

import ProtectedRoute from './components/Login/ProtectedRoute';
import Login from './components/Login/Login';

import VoicesPage from './components/Voices/VoicesPage';
import './App.css';
import './pickford_styles.scss';
import { Helmet } from 'react-helmet';
import { useUser } from './contexts/user-context';
import AuthError from './components/Login/auth-error';

interface AuthErrorBoundaryProps {
    children: ReactNode;
}

const AuthErrorBoundary: React.FC<AuthErrorBoundaryProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { error } = useUser();

    useEffect(() => {
        if (error && location.pathname !== '/auth-error') {
            navigate('/auth-error');
        }
    }, [error, navigate, location]);

    return <>{children}</>;
};

const App: React.FC = () => {
    const { isLoggedIn, logout, loading } = useUser();

    if (loading) {
        return isLoggedIn ? (
            <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                <img src="/pickford_wordmark_black.png" alt="Pickford Logo" className="img-fluid mb-3" style={{ maxWidth: '250px' }} />
                <div className="text-center">Loading</div>
            </div>
        ) : (
            <Login />
        );
    }

    return (
        <Router>
            <AuthErrorBoundary>
                <div className="App">
                    <Helmet>
                        <script type="text/javascript">
                            {`
                                (function(c,l,a,r,i,t,y){
                                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                })(window, document, "clarity", "script", "nx4cy33fbc");
                            `}
                        </script>
                    </Helmet>
                    <Header isLoggedIn={isLoggedIn} onLogout={logout} />
                    <Routes>
                        <Route path="/" element={isLoggedIn ? <Navigate to="/projects" /> : <Login />} />
                        <Route path="/auth-error" element={<AuthError />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path="/projects" element={<ProjectsView />} />
                            <Route path="/project/:id" element={<StudioView />} />
                            <Route path="/voices" element={<VoicesPage />} />
                        </Route>
                    </Routes>
                </div>
            </AuthErrorBoundary>
        </Router>
    );
};

export default App;
