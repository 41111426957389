import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ProjectsView.css';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getProjects, createNewProject } from '../../utils/projects';
import { useUser } from '../../contexts/user-context';

const TAB_STORAGE_KEY = 'studioActiveTab';
const STORY_INPUT_MODE_KEY = 'studioStoryInputMode';

const ProjectsView = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { setError, sessionToken } = useUser();
    const fetchingRef = useRef(false);

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem(TAB_STORAGE_KEY, 'Story');
    }, []);

    useEffect(() => {
        localStorage.setItem(STORY_INPUT_MODE_KEY, 'Basic');
    }, []);

    const fetchProjects = useCallback(async () => {
        if (!sessionToken || fetchingRef.current) return;
        fetchingRef.current = true;
        try {
            const projectsData = await getProjects();
            setProjects(projectsData);
        } catch (error) {
            if (error.message === 'No refresh token available') {
                setError('Your session has expired. Please log in again.');
            } else if (error.message === 'Unauthorized') {
                setError('Unauthorized');
            } else {
                setError(`Error fetching projects: ${error.message}`);
            }
        } finally {
            fetchingRef.current = false;
        }
    }, [sessionToken, setError]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleEditClick = projectId => {
        navigate(`/project/${projectId}`);
    };

    const handleNewProjectClick = async () => {
        try {
            await createNewProject(setError, navigate);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error creating new project:', error);
            setError(`Error creating new project: ${error.message}`);
            if (error.message === 'Unauthorized') {
                setError('Unauthorized');
            }
        }
    };

    const filteredProjects = projects.filter(project => project.title.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <Container className="container-full-height mt-4 p-3 border shadow-sm rounded bg-white">
            <Row className="mb-4">
                <Col>
                    <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
                </Col>
                <Col xs="auto">
                    <Button onClick={handleNewProjectClick}>New Project</Button>
                </Col>
            </Row>
            <div className="content">
                <Row>
                    {filteredProjects.map(project => (
                        <Col key={project.id} sm={6} md={4} lg={3} className="mb-4 project-col">
                            <Card className="project-card">
                                <Card.Img variant="top" src={project.image} className="project-image" />
                                <Button variant="secondary" className="edit-button" size="sm" onClick={() => handleEditClick(project.id)}>
                                    Edit
                                </Button>
                                <Card.Body>
                                    <Card.Title className="projects-project-title">{project.title}</Card.Title>
                                    <Card.Text className="project-description">{project.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
};

export default ProjectsView;
