import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';
import { OrbitControls } from 'three/examples/jsm/Addons.js';
import { ThreeRefs } from '../../types/ThreeRefs';
import { Character } from '../../types/Character';

interface SceneInitOptions {
    threeRefs: ThreeRefs;
    setError: (error: string) => void;
}

export const initializeScene = (glbFile: string, { threeRefs, setError }: SceneInitOptions): void => {
    const { threeContainerRef, sceneRef } = threeRefs;

    if (!threeContainerRef.current) return;

    // Clear previous scene
    if (sceneRef.current) {
        sceneRef.current.clear();
        while (threeContainerRef.current.firstChild) {
            threeContainerRef.current.removeChild(threeContainerRef.current.firstChild);
        }
    }

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        75,
        threeContainerRef.current.clientWidth / threeContainerRef.current.clientHeight,
        0.1,
        1000,
    );

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(threeContainerRef.current.clientWidth, threeContainerRef.current.clientHeight);
    renderer.setClearColor(0x000000, 0);
    threeContainerRef.current.appendChild(renderer.domElement);

    // Add lights
    const setupLights = (scene: THREE.Scene) => {
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);

        const pointLight1 = new THREE.PointLight(0xffffff, 1);
        pointLight1.position.set(5, 5, 5);
        scene.add(pointLight1);

        const pointLight2 = new THREE.PointLight(0xffffff, 1);
        pointLight2.position.set(-5, -5, -5);
        scene.add(pointLight2);
    };

    setupLights(scene);

    const loader = new GLTFLoader();
    loader.load(
        glbFile,
        gltf => {
            const model = gltf.scene;
            scene.add(model);
            model.position.set(0, 0, 0);

            const scale = 1.5;
            model.scale.set(scale, scale, scale);
            model.rotation.y = Math.PI;

            const box = new THREE.Box3().setFromObject(model);
            const size = box.getSize(new THREE.Vector3()).length();
            const center = box.getCenter(new THREE.Vector3());

            camera.position.set(center.x, center.y, size * 0.6);

            const controls = new OrbitControls(camera, renderer.domElement);
            controls.target.copy(center);
            controls.update();
            threeRefs.controlsRef.current = controls;

            const animate = () => {
                requestAnimationFrame(animate);
                controls.update();
                renderer.render(scene, camera);
            };

            animate();
        },
        undefined,
        error => {
            setError(`Error loading glb file: ${error}`);
        },
    );

    sceneRef.current = scene;
};

export const createNewCharacter = (index: number): Character => ({
    name: `Character ${index + 1}`,
    visual_description: 'A woman in an astronaut suit. It is faded and dusty with a logo on the chest.',
    personality_summary: '',
    motivation: '',
});

export const updateCharacterAttributes = (data: Character[], characterIndex: number, newAttributes: Partial<Character>): Character[] => {
    const newCharacters = [...data];
    newCharacters[characterIndex] = {
        ...newCharacters[characterIndex],
        ...newAttributes,
    };
    return newCharacters;
};
