// hooks/useCharacterGeneration.ts
import { useState } from 'react';
import { Character } from '../../types/Character';
import { textToImage, imageTo3D, rigCharacter } from '../../utils/generateCharacter';
import { updateCharacterAttributes } from './CharactersUtility';

interface CharacterGenerationHook {
    handleGenerate2DImage: (characterId: number) => Promise<void>;
    handleGenerate3DImage: (characterId: number) => Promise<void>;
    handleGenerateRig: (characterId: number) => Promise<void>;
    rigLoading: boolean;
    rigError: boolean;
}

interface UseCharacterGenerationProps {
    data: Character[];
    updateData: (characters: Character[]) => void;
    setLoading: (loading: boolean) => void;
    setLoadingText: (text: string) => void;
    setError: (error: string) => void;
}

export const useCharacterGeneration = ({
    data,
    updateData,
    setLoading,
    setLoadingText,
    setError,
}: UseCharacterGenerationProps): CharacterGenerationHook => {
    const [rigLoading, setRigLoading] = useState(false);
    const [rigError, setRigError] = useState(false);

    const handleGenerate2DImage = async (characterId: number) => {
        const prompt = `
        A SINGLE view of a humanoid character. Front facing, t-posed, legs shoulder length apart, centred on an all white background with their full body visible. Ignore any tools or extras in the 'Subject'.
        Subject: ${data[characterId].visual_description}
        Style: Animated
        `;

        try {
            const image2DUrl = await textToImage(prompt, setLoading, setLoadingText, setError, { responseFormat: 'url' });
            const newCharacters = updateCharacterAttributes(data, characterId, { image_2d_url: image2DUrl });
            updateData(newCharacters);
        } catch (error) {
            setError(`Error generating 2D image: ${error}`);
        }
    };

    const handleGenerate3DImage = async (characterId: number) => {
        const selectedCharacter = data[characterId];

        try {
            if (!selectedCharacter.image_2d_url) {
                await handleGenerate2DImage(characterId);
            }

            const { zip_file_url, obj_file_name, mtl_file_name, img_file_name, glb_file_url } = await imageTo3D(
                selectedCharacter.image_2d_url!,
                setLoading,
                setLoadingText,
                setError,
            );

            const newCharacters = updateCharacterAttributes(data, characterId, {
                image_3d_url: glb_file_url,
                image_3d_obj_file_name: obj_file_name,
                image_3d_mtl_file_name: mtl_file_name,
                image_3d_img_file_name: img_file_name,
                image_3d_zip_file_url: zip_file_url,
            });
            updateData(newCharacters);
        } catch (error) {
            setError(`Error generating 3D image: ${error}`);
            setTimeout(() => setError(''), 10000);
        }
    };

    const handleGenerateRig = async (characterId: number) => {
        const selectedCharacter = data[characterId];

        if (selectedCharacter.image_3d_zip_file_url && selectedCharacter.image_3d_obj_file_name) {
            setRigLoading(true);
            const riggedFileUrl = await rigCharacter(
                selectedCharacter.image_3d_zip_file_url,
                selectedCharacter.image_3d_obj_file_name,
                setError,
            );

            if (riggedFileUrl) {
                const newCharacters = updateCharacterAttributes(data, characterId, { rigged_file_url: riggedFileUrl });
                updateData(newCharacters);
                setRigError(false);
            } else {
                setRigError(true);
            }
            setRigLoading(false);
        }
    };

    return {
        handleGenerate2DImage,
        handleGenerate3DImage,
        handleGenerateRig,
        rigLoading,
        rigError,
    };
};
