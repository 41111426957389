import React from 'react';
import './Header.css';
import { Nav, Navbar, Dropdown, Image } from 'react-bootstrap';
import Cookies from 'js-cookie';

interface HeaderProps {
    onLogout: () => void;
    isLoggedIn: boolean;
}

const Header: React.FC<HeaderProps> = ({ onLogout, isLoggedIn }) => {
    const userPicture = Cookies.get('user_picture') || '';

    return (
        <Navbar className="p-0" sticky="top" expand="lg" bg="primary" data-bs-theme="dark">
            <span className="navbar-logo-wrapper">
                <Image src="/pickford_logomark_colour_blk.png" alt="Pickford Logo" className="navbar-logo" />
            </span>
            <Navbar.Brand className="ms-3 p-0">
                <span className="pickford_font_subhead">PICKFORD | </span>
                <span className="pickford_font_head">STUDIO</span>
            </Navbar.Brand>
            {isLoggedIn && (
                <>
                    <Nav className="me-auto">
                        <Nav.Link href="/projects">Projects</Nav.Link>
                        <Nav.Link disabled>Community</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <Image src={userPicture} roundedCircle width="45" height="45" alt="Account" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </>
            )}
        </Navbar>
    );
};

export default Header;
