import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/user-context';

const ProtectedRoute = () => {
    const { isLoggedIn, loading } = useUser();
    const location = useLocation();

    if (loading) {
        return null;
    }

    if (!isLoggedIn) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
