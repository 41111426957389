import { DefaultCharacter } from '../../types/Character';
import React, { useState } from 'react';
import { Modal, Carousel, Button } from 'react-bootstrap';

import './DefaultCharacterSelector.css';

export const DEFAULT_CHARACTERS: DefaultCharacter[] = [
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/phoebe.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/phoebe.glb',
    },
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/rachel.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/rachel.glb',
    },
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/monica.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/monica.glb',
    },
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/joery.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/joey.glb',
    },
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/chandler.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/chandler.glb',
    },
    {
        image_2d_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/ross.png',
        rigged_file_url: 'https://pickford-studio-public-assets-prod.s3.us-east-2.amazonaws.com/defaults/ross.glb',
    },
];

interface DefaultCharacterSelectorProps {
    onSelectedCharacter: (selectedCharacterAttributes: DefaultCharacter, selectedModelIndex: number) => void;
    handleClose: () => void;
    show: boolean;
    characterName: string;
    preselectedIndex: number;
}

const DefaultCharacterSelector: React.FC<DefaultCharacterSelectorProps> = ({
    onSelectedCharacter,
    handleClose,
    show,
    characterName,
    preselectedIndex,
}) => {
    const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(preselectedIndex);

    const handleSelect = () => {
        onSelectedCharacter(DEFAULT_CHARACTERS[selectedCharacterIndex], selectedCharacterIndex);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select a Model For {characterName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel activeIndex={selectedCharacterIndex} interval={null} variant="dark" onSelect={setSelectedCharacterIndex}>
                    {DEFAULT_CHARACTERS.map((character, index) => (
                        <Carousel.Item key={index}>
                            <img src={character.image_2d_url} className="carousel-image" alt={`Example Character ${index + 1}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleSelect()}>
                    Select Model
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DefaultCharacterSelector;
