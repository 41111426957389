// components/CharactersView.tsx
// React and General Imports
import React, { useState, useEffect, useRef } from 'react';

// External Libraries
import { Scene, OrbitControls } from 'three';
import { Form, Container, Row, Col, Button, CloseButton, Badge } from 'react-bootstrap';
import { BsUpload, BsDownload } from 'react-icons/bs';

// CSS/Styling
import './CharactersView.css';

// Utility Functions
import { constructPublicUrl } from '../../utils/s3';
import { getEnvVariable } from '../../utils/env';
import { createNewCharacter, updateCharacterAttributes, initializeScene } from './CharactersUtility';

// Custom Components and Types
import VoicesPage, { VOICES } from '../Voices/VoicesPage';
import DefaultCharacterSelector, { DEFAULT_CHARACTERS } from './DefaultCharacterSelector';
import { Character, DefaultCharacter } from '../../types/Character';
import { CharactersViewProps } from '../../types/Props';
import { ThreeRefs } from '../../types/ThreeRefs';
import { useCharacterGeneration } from './CharacterHooks';

const CharactersView: React.FC<CharactersViewProps> = ({ data, updateData, setLoading, setLoadingText, setError }) => {
    const CHARACTER_MODEL_DEFAULT = getEnvVariable('REACT_APP_CHARACTERS_VERSION') === 'DEFAULT';

    const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(0);
    const [showVoicesPage, setShowVoicesPage] = useState(false);
    const [showCharacterModelsPage, setShowCharacterModelsPage] = useState(false);
    const [chosenIndex, setChosenIndex] = useState(0);

    const threeContainerRef = useRef<HTMLDivElement>(null);
    const controlsRef = useRef<OrbitControls>(null);

    const sceneRef = useRef<Scene>(null);

    const threeRefs: ThreeRefs = {
        threeContainerRef,
        controlsRef,
        sceneRef,
    };

    const { handleGenerate2DImage, handleGenerate3DImage, handleGenerateRig, rigLoading, rigError } = useCharacterGeneration({
        data,
        updateData,
        setLoading,
        setLoadingText,
        setError,
    });

    const handleCharacterChange = (index: number) => {
        setSelectedCharacterIndex(index);
        setChosenIndex(data[index].image_2d_url ? DEFAULT_CHARACTERS.map(obj => obj.image_2d_url).indexOf(data[index].image_2d_url) : 0);
    };

    const handleVoiceSelection = (voiceId: string) => {
        const newCharacters = updateCharacterAttributes(data, selectedCharacterIndex, { voice_id: voiceId });
        updateData(newCharacters);
        setShowVoicesPage(false);
    };

    const handleCharacterModelSelection = (selectedCharacterAttributes: DefaultCharacter, selectedModelIndex: number) => {
        const newCharacters = updateCharacterAttributes(data, selectedCharacterIndex, selectedCharacterAttributes);
        setChosenIndex(selectedCharacterIndex);
        updateData(newCharacters);
        setShowCharacterModelsPage(false);
    };

    const removeCharacter = () => {
        const updatedCharacters = data.filter((_, i) => i !== selectedCharacterIndex);
        updateData(updatedCharacters);
        if (selectedCharacterIndex === selectedCharacterIndex && updatedCharacters.length > 0) {
            setSelectedCharacterIndex(Math.max(0, selectedCharacterIndex - 1));
        }
    };

    const handleChange = (field: keyof Character, value: string) => {
        const newCharacters = updateCharacterAttributes(data, selectedCharacterIndex, { [field]: value });
        updateData(newCharacters);
    };

    const addCharacter = () => {
        const newCharacter = createNewCharacter(data.length);
        const newCharacters = [...data, newCharacter];
        updateData(newCharacters);
        setSelectedCharacterIndex(newCharacters.length - 1);
    };

    const handleNameChange = (event: React.FocusEvent<HTMLDivElement>) => {
        handleChange('name', event.currentTarget.innerText);
    };

    const handleDownload = (url: string, filename: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUpload2DImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const tmpUrl = URL.createObjectURL(file);
            const newCharacters = updateCharacterAttributes(data, selectedCharacterIndex, {
                image_2d: file,
                image_2d_url: tmpUrl,
            });
            updateData(newCharacters);
        }
    };

    const handleUpload3DImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const tmpUrl = URL.createObjectURL(file);
            const newCharacters = updateCharacterAttributes(data, selectedCharacterIndex, {
                image_3d: file,
                image_3d_url: tmpUrl,
            });
            updateData(newCharacters);
        }
    };

    const selectedCharacter = data[selectedCharacterIndex];

    useEffect(() => {
        if (selectedCharacter?.image_3d_url) {
            const publicGlbFileUrl = constructPublicUrl(selectedCharacter.image_3d_url, getEnvVariable('REACT_APP_AWS_REGION'));
            initializeScene(publicGlbFileUrl, { threeRefs, setError });
        }
    }, [data]);

    useEffect(() => {
        if (
            selectedCharacter?.image_3d_obj_file_name &&
            selectedCharacter?.image_3d_mtl_file_name &&
            selectedCharacter?.image_3d_img_file_name &&
            !selectedCharacter?.rigged_file_url
        ) {
            handleGenerateRig(selectedCharacterIndex);
        }
    }, [data]);

    if (data.length === 0) {
        return (
            <Container>
                <Row className="mt-3">
                    <Col className="justify-content-start">
                        <Button variant="primary" onClick={addCharacter}>
                            Add Character
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    const CharacterModelsGenerated = () => {
        return (
            <Row className="mb-3">
                {/* 2D Image Column */}
                <Col>
                    <div className="position-relative border rounded p-2" style={{ minHeight: '268px', backgroundColor: '#e9ecef' }}>
                        {selectedCharacter.image_2d_url ? (
                            <img
                                src={constructPublicUrl(selectedCharacter.image_2d_url)}
                                alt="2D Image"
                                className="img-fluid rounded"
                                style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }}
                            />
                        ) : (
                            <span>2D Image</span>
                        )}

                        <label htmlFor="upload2D" className="btn btn-light position-absolute" style={{ top: '10px', right: '10px' }}>
                            <BsUpload />
                        </label>
                        <input id="upload2D" accept="image/*" type="file" className="d-none" onChange={handleUpload2DImage} />

                        {selectedCharacter.image_2d_url && (
                            <Button
                                variant="light"
                                className="position-absolute"
                                style={{ top: '50px', right: '10px' }}
                                onClick={() =>
                                    handleDownload(constructPublicUrl(selectedCharacter.image_2d_url!), `${selectedCharacter.name}_2D.png`)
                                }
                            >
                                <BsDownload />
                            </Button>
                        )}
                    </div>
                    <Button variant="secondary" className="mt-2 w-100" onClick={() => handleGenerate2DImage(selectedCharacterIndex)}>
                        Generate
                    </Button>
                </Col>

                {/* 3D Image Column */}
                <Col>
                    <div className="position-relative border rounded p-2" style={{ minHeight: '268px', backgroundColor: '#e9ecef' }}>
                        {selectedCharacter.image_3d_url ? (
                            <div ref={threeContainerRef} className="three-container" />
                        ) : (
                            <span>3D Image</span>
                        )}

                        <label htmlFor="upload3D" className="btn btn-light position-absolute" style={{ top: '10px', right: '10px' }}>
                            <BsUpload />
                        </label>
                        <input id="upload3D" type="file" accept=".glb" className="d-none" onChange={handleUpload3DImage} />

                        {selectedCharacter.image_3d_url && (
                            <Button
                                variant="light"
                                className="position-absolute"
                                style={{ top: '50px', right: '10px' }}
                                onClick={() =>
                                    handleDownload(constructPublicUrl(selectedCharacter.image_3d_url!), `${selectedCharacter.name}_3D.glb`)
                                }
                            >
                                <BsDownload />
                            </Button>
                        )}

                        {/* Rigging Status Badges */}
                        {rigLoading && (
                            <Badge bg="warning" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                Rigging
                            </Badge>
                        )}
                        {selectedCharacter.image_3d_url && !selectedCharacter.rigged_file_url && !rigLoading && !rigError && (
                            <Badge bg="secondary" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                Not Rigged
                            </Badge>
                        )}
                        {selectedCharacter.rigged_file_url && !rigLoading && (
                            <Badge bg="success" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                Rigged
                            </Badge>
                        )}
                        {rigError && !rigLoading && (
                            <Badge bg="danger" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                Rig Error
                            </Badge>
                        )}
                    </div>
                    <Col className="d-flex">
                        <Button
                            variant="secondary"
                            className={rigError ? 'mt-2 w-50 me-1' : 'mt-2 w-100'}
                            onClick={() => {
                                // TODO: figure out promise handling
                                void handleGenerate3DImage(selectedCharacterIndex);
                                void handleGenerateRig(selectedCharacterIndex);
                            }}
                        >
                            Generate
                        </Button>
                        {!rigLoading && (
                            <Button variant="secondary" className="mt-2 w-50" onClick={() => handleGenerateRig(selectedCharacterIndex)}>
                                Rig
                            </Button>
                        )}
                    </Col>
                </Col>
            </Row>
        );
    };

    const CharacterModelDefault = ({ character }: { character: Character }) => {
        const modelChosen = character.image_2d_url;
        const chosenIndex = 0;

        return (
            <Container className="text-center">
                {modelChosen ? (
                    <img
                        src={constructPublicUrl(character.image_2d_url)}
                        alt={'character model'}
                        style={{ maxWidth: '50%', display: 'block', margin: '0 auto' }}
                    />
                ) : (
                    <h3 className="text-center">No model selected for this character.</h3>
                )}
                <Button
                    className="m-2"
                    onClick={() => {
                        setShowCharacterModelsPage(true);
                    }}
                >
                    {modelChosen ? 'Change my Character Model' : 'Select a Character Model'}
                </Button>
                <DefaultCharacterSelector
                    characterName={character.name}
                    handleClose={() => setShowCharacterModelsPage(false)}
                    onSelectedCharacter={handleCharacterModelSelection}
                    show={showCharacterModelsPage}
                    preselectedIndex={chosenIndex}
                />
            </Container>
        );
    };

    return (
        <div className="characters-view">
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={8} md={8} className="border p-4 shadow rounded position-relative">
                        <VoicesPage
                            show={showVoicesPage}
                            handleClose={() => setShowVoicesPage(false)}
                            onSelectVoice={handleVoiceSelection}
                        />

                        <div className="character-name" contentEditable suppressContentEditableWarning onBlur={handleNameChange}>
                            {selectedCharacter.name}
                        </div>

                        <CloseButton className="position-absolute" style={{ top: '10px', right: '10px' }} onClick={removeCharacter} />

                        {CHARACTER_MODEL_DEFAULT ? CharacterModelDefault({ character: selectedCharacter }) : CharacterModelsGenerated()}

                        {/* Form Groups */}
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Visual Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedCharacter.visual_description}
                                    onChange={e => handleChange('visual_description', e.target.value)}
                                    placeholder="Information on what they look like."
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Voice:{' '}
                                    {selectedCharacter.voice_id
                                        ? VOICES.find(voice => voice.voice_id === selectedCharacter.voice_id)?.name
                                        : ''}
                                </Form.Label>
                                <Button className="m-2" onClick={() => setShowVoicesPage(true)}>
                                    {selectedCharacter.voice_id ? 'Change Voice' : 'Select Voice'}
                                </Button>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Personality Summary</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedCharacter.personality_summary}
                                    onChange={e => handleChange('personality_summary', e.target.value)}
                                    placeholder="Information on their personality."
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Motivation</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedCharacter.motivation}
                                    onChange={e => handleChange('motivation', e.target.value)}
                                    placeholder="What is their motivation?"
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                {/* Character Selection Row */}
                <Row className="justify-content-center mt-3">
                    <Col xs={14} md={14} className="d-flex justify-content-center">
                        <div className="p-2 w-100" style={{ overflowX: 'auto', border: '1px solid #dee2e6', borderRadius: '0.25rem' }}>
                            <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
                                {data.map((character, index) => (
                                    <Button
                                        key={index}
                                        variant="outline-primary"
                                        className={selectedCharacterIndex === index ? 'active' : ''}
                                        onClick={() => handleCharacterChange(index)}
                                        style={{ flex: '0 0 auto', marginRight: '10px' }}
                                    >
                                        {character.name}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Add Character Button */}
            <Container>
                <Row className="mt-3">
                    <Col className="justify-content-start">
                        <Button variant="primary" onClick={addCharacter}>
                            Add Character
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CharactersView;
