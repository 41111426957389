import JSZip from 'jszip';
import { constructPublicUrl } from './s3';

class PickfordPackage {
    private readonly packageBlobs: { [key: string]: Blob };

    constructor() {
        this.packageBlobs = {};
    }

    addScriptJson(scriptJson: string): void {
        this.packageBlobs['script.json'] = new Blob([scriptJson], { type: 'application/json' });
    }

    addScriptText(scriptText: string): void {
        this.packageBlobs['script.txt'] = new Blob([scriptText], { type: 'text/plain' });
    }

    async addCharacter(blobUrl: string, characterName: string, fileSuffix = 'glb'): Promise<void> {
        const response = await fetch(blobUrl.includes('s3://') ? constructPublicUrl(blobUrl) : blobUrl);
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        const fileName = `${characterName.toLowerCase().replace(/ /g, '_')}.${fileSuffix}`;
        this.packageBlobs[`characters/${fileName}`] = blob;
    }

    async compressPackage(): Promise<Blob> {
        const zip = new JSZip();
        Object.keys(this.packageBlobs).forEach(key => {
            zip.file(key, this.packageBlobs[key]);
        });
        return await zip.generateAsync({ type: 'blob' });
    }
}

export default PickfordPackage;
