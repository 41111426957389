import { getEnvVariable } from './env';
import { Character } from '../types/Character';

const MAIN_API_URL = getEnvVariable('REACT_APP_MAIN_API_URL');

export const parseCharacters = async (script: string, setError: (message: string) => void): Promise<Character[]> => {
    const url = `${MAIN_API_URL}/parse_characters`;

    const bodyData = JSON.stringify({ script });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: bodyData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const charactersData = JSON.parse(data.characters).script_characters;

        return charactersData.map((character: Character) => ({
            name: character.name,
            visual_description: character.visual_description,
            personality_summary: character.personality_summary,
            motivation: character.motivation,
        }));
    } catch (error) {
        setError('Failed to parse characters. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return []; // Return an empty array in case of an error
    }
};
