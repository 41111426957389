import React from 'react';
import './SelectionBar.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

// Define the props types for SelectionBar
interface SelectionBarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

const SelectionBar: React.FC<SelectionBarProps> = ({ activeTab, setActiveTab }) => {
    const comingSoon: string[] = ['Sets'];

    return (
        <div className="App-tabs">
            <ButtonGroup>
                {['Story', 'Script', 'Characters', 'Dialogue', 'Sets', 'Cameras'].map(tab => (
                    <Button
                        key={tab}
                        variant="outline-secondary"
                        className={activeTab === tab ? 'active' : ''}
                        onClick={() => setActiveTab(tab)}
                        disabled={comingSoon.includes(tab)}
                        title={comingSoon.includes(tab) ? 'Coming Soon...' : tab}
                    >
                        {tab}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    );
};

export default SelectionBar;
