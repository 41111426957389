import React, { useEffect, useRef, useState } from 'react';
import { getEnvVariable } from '../../utils/env';

const UNITY_BUILD_URL = getEnvVariable('REACT_APP_UNITY_BUILD_URL');

const UnityPlayer = ({ packageZipURL, setVideoUrl }) => {
    const canvasRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const loaderUrl = `${UNITY_BUILD_URL}/Build/AutomatedBuild.loader.js`;
    const frameworkUrl = `${UNITY_BUILD_URL}/Build/AutomatedBuild.framework.js`;
    const dataUrl = `${UNITY_BUILD_URL}/Build/AutomatedBuild.data`;
    const wasmUrl = `${UNITY_BUILD_URL}/Build/AutomatedBuild.wasm`;
    const [unityInstance, setUnityInstance] = useState(null);

    useEffect(() => {
        // Load the Unity loader script dynamically
        const loadUnityScript = async () => {
            if (typeof window.createUnityInstance === 'undefined') {
                // Inject the loader script into the DOM
                const script = document.createElement('script');
                script.src = loaderUrl;
                script.async = true;

                script.onload = () => {
                    setIsLoaded(true);
                };

                script.onerror = error => {
                    // eslint-disable-next-line no-console
                    console.error('Error loading Unity loader script:', error);
                };

                document.body.appendChild(script);
                return () => {
                    document.body.removeChild(script);
                };
            } else {
                setIsLoaded(true);
            }
        };

        loadUnityScript();
    }, [loaderUrl]);

    useEffect(() => {
        if (isLoaded && canvasRef.current) {
            const config = {
                dataUrl: dataUrl,
                frameworkUrl: frameworkUrl,
                codeUrl: wasmUrl,
                streamingAssetsUrl: `${UNITY_BUILD_URL}/StreamingAssets/`,
                companyName: 'Pickford.AI',
                productName: 'Pickford.Renderer',
                productVersion: '0.1',
                showBanner: (msg, type) => {
                    // eslint-disable-next-line no-console
                    console.log(`[Unity ${type}] ${msg}`);
                },
            };
            // eslint-disable-next-line no-console
            console.log('Unity Config', config);

            window
                .createUnityInstance(canvasRef.current, config)
                .then(unityInstance => {
                    // eslint-disable-next-line no-console
                    console.log('Unity instance created successfully');
                    setUnityInstance(unityInstance);
                })
                .catch(message => {
                    // eslint-disable-next-line no-console
                    console.error('Unity instance creation failed:', message);
                });
        }
    }, [dataUrl, frameworkUrl, isLoaded, wasmUrl]);

    useEffect(() => {
        if (unityInstance && packageZipURL) {
            unityInstance.SendMessage('The Director', 'OnNewPackageUrl', packageZipURL);
            // TODO: somehow return video url after it's rendered and set using setVideoUrl
        }
    }, [unityInstance, packageZipURL]);

    return (
        <div id="unity-container" className="unity-desktop" style={{ width: '100%', height: '100%' }}>
            <canvas id="unity-canvas" style={{ width: '100%', height: '100%' }} tabIndex="-1" ref={canvasRef}></canvas>
            <div id="unity-loading-bar">
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                    <div id="unity-progress-bar-full"></div>
                </div>
            </div>
            <div id="unity-warning"> </div>
            <div id="unity-footer">
                <div id="unity-webgl-logo"></div>
                <div id="unity-fullscreen-button"></div>
            </div>
        </div>
    );
};

export default UnityPlayer;
